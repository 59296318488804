import React, { useState } from "react"
import axios from 'axios'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../../components/seo"
import Img from "../../../components/image-transform"


const Churn = () => {
  const [onSubmit, setOnSubmit] = useState({message: "Download now", isSubmitted: false });
  const [emailAdd, hasEmail] = useState('')
  const [name, hasName] = useState('')
  const [company, hasCompany] = useState('')
  const [error, isError] = useState()
  const [disabled, isDisabled] = useState(true)

  const handleChange = event => {
    hasEmail(event.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }

  const handleChangeName = eventi => {
    hasName(eventi.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  const handleChangeCompany = eventj => {
    hasCompany(eventj.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  
  const handleSubmit = event => {
    event.preventDefault();

    const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const result = pattern.test(emailAdd);

    if (result === true) {
      isError(false)
      isDisabled(true)
      setOnSubmit({message: "Sending...", isSubmitted: false });

      axios({
        method: 'post',
        url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/89953448/subscribers`,
        headers: { 'content-type': 'application/json', 'X-MailerLite-ApiKey': 'f13b6f484a31a41bbdefdd972b1d6099'  },
        data: {
          email: emailAdd,
          name: name,
          type: 'active',
          fields: {
            company: company
          }
        }
      }).then(res=> {
          setOnSubmit({message: "Thanks! We've emailed your eBook to you.", isSubmitted: true });
          hasEmail('');
          hasName('');
          hasCompany('');
      }).catch((error) => {
        setOnSubmit({message: "Ops! Please try again.", isSubmitted: false });
      })
    } else (
      isError(true)
    )
  };

  return (
    <Layout>
      <SEO 
        title="Reduce Churn and Retain Customers | Free eBook" 
        description="Learn 20 ways to reduce churn and retain customers with our free eBook to improve the results of your subscription billing business." 
        url="https://www.billsby.com/resources/guides/20-ways-to-reduce-churn-and-retain-more-customers"
      />

      <SEO 
        title="Reduce Churn and Retain Customers | Free eBook"
        meta={[
          {
            name: `description`,
            description: `Learn 20 ways to reduce churn and retain customers with our free eBook to improve the results of your subscription billing business.`,
          },
          // {
          //   property: `og:type`,
          //   content: `website`,
          // },
          {
            property: `og:url`,
            content: `https://www.billsby.com/resources/guides/20-ways-to-reduce-churn-and-retain-more-customers`,
          },
          {
            property: `og:title`,
            content: `Reduce Churn and Retain Customers | Free eBook | Billsby`,
          },
          {
            property: `og:description`,
            description: `Learn 20 ways to reduce churn and retain customers with our free eBook to improve the results of your subscription billing business.`,
          },
          // {
          //   property: `og:image`,
          //   content: `/src/images/BillsbyOG.png`,
          // },
          // {
          //   name: `twitter:card`,
          //   content: `summary_large_image`,
          // },
          {
            name: `twitter:url`,
            content: `https://www.billsby.com/resources/guides/20-ways-to-reduce-churn-and-retain-more-customers`,
          },
          {
            name: `twitter:title`,
            content: `Reduce Churn and Retain Customers | Free eBook | Billsby`,
          },
          {
            name: `twitter:description`,
            description: `Learn 20 ways to reduce churn and retain customers with our free eBook to improve the results of your subscription billing business.`,
          },
          // {
          //   name: `twitter:image`,
          //   content: `/src/images/BillsbyOG.png`,
          // },
        ]}
      />

      <div className="ebook churn">
        <div className="section-hero">
          <div className="container container-flex">
            <div className="section-content">
              <h1 className="section-heading">20 ways to reduce churn and retain more customers.</h1>
              {/* <p className="section-text">For most subscription business owners and managers, the dreaded c-word needs no introduction. Churn can destroy your business fast and can seemingly come out of nowhere, taking your customers and your profits with it.</p> */}
              <p className="section-text">For most subscription business owners and managers, the dreaded c-word needs no introduction. Churn can destroy your business fast and can seemingly come out of nowhere, taking your customers and your profits with it. So how can you combat the storm, right the ship and make sure your business operates with the lowest possible churn? We’ve spoken to industry experts, researched the best solutions and gathered together all of our wisdom into this eBook to help you reduce your churn and retain more customers.</p>

              <a className="btn-orange" href="#ebookForm">Download free eBook</a>
            </div>
            <div className="section-image">
              <Img filename="ebook-churn.png" className="main-img"  alt="20 ways to reduce churn and retain more customers." />
            </div>
          </div>
        </div>
        <div className="section-ebook">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">1. Tackle early life churn with pro-active support</h2>
              <p className="section-text">If you see customers cancelling early in the lifecycle - such as during a free trial, or within the first couple of months, chances are they didn’t get the opportunity to fully understand what your service is offering - or they encountered problems and gave up rather than seeking assistance.</p>
              <p className="section-text">Optimising your product to help these customers is as simple as understanding where the drop-off points are, and then offering help and support proactively. </p>
              <p className="section-text"> We typically recommend sending an email to customers who finish a setup process or tutorial part way through, contacting customers who aren’t logging in to your product or service as much or - for physical service providers - reaching out if you see your customers aren’t coming in and using their service. Even if they do churn, reaching out and understanding why will help you to improve your service for the next customer. Much better than just wondering what’s going on.</p>     
              <p className="section-title">Remind customers they can reach out with every invoice</p> 
              <p className="section-text">With Billsby, every invoice is accompanied by the invoice sidebar, where you can proactively invite customers to contact you with any questions, or reach out for support. You can advertise tutorials and getting started content too. This helps reduce both your churn and your chargebacks, because when customers don’t agree with their bill, they know who to talk to.</p>
            </div>
            <div className="ebook-image">
              <img src={require('../../../images/pro-active-support.svg')} alt="Active Support"/>
            </div>
          </div>
        </div>
        <div className="section-ebook">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">2. When customers leave, ask them why</h2>
              <p className="section-text">Understanding the reasons customers leave is an essential part of your churn strategy, because you can use leaver data to make improvements for future customers. After all, you wouldn’t let an employee go without conducting an exit interview, so why wouldn’t you extend the same practice to your customers? Free form builders like Typeform can be used to create simple exit surveys that you can display on your cancellation page or email out to customers after they leave. We recommend a simple, two-step approach (long, drawn out surveys don’t get many replies, after all!).</p>
              <p className="section-text">First, ask customers why they’ve left. We advocate for three options - I don’t use it anymore, it was too expensive and I switched to a competitor. These are the three main reasons people cancel most subscriptions - although for your specific business, other options might make sense.</p>
              <p className="section-text">Then, ask customers to expand with some free comments to help you really understand the reason they left. We recommend sifting through these comments for common strands, and using them to improve your service. You could even reach out to customers and ask them to expand on their thoughts - or try and win them back with a human connection.</p>
              <p className="section-title">Ask customers why they're leaving before they leave</p>
              <p className="section-text">With Billsby, cancellation isn’t just about stopping payments. Our retention tools let you capture exit information before customers leave, and entice them to stay with targeted offers, discounts and proactive support prompts.</p>
              <p className="section-text">This means that customers who are on the fence will have every reason to stick around, and are much less likely to churn.</p>
            </div>
            <div className="ebook-image">
              <img src={require('../../../images/support-mail.svg')} alt="support mail"/>
            </div>
          </div>
        </div>
        <div className="section-ebook">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">3. Use in-life feedback to identify potential churn risks</h2>
              <p className="section-text">Wouldn’t it be great to know that a customer was a churn risk before they even thought about leaving? In-life feedback is a great way to understand which of your customers aren’t as satisfied as they could be. This NPS email from Insurify is great, because customers can answer by just clicking one button.</p>
              <p className="section-text">Customers who rate their experience poorly could be added to a special list and reached out to, and customers who rate the service well could be invited to leave a review to help attract more customers to the service.</p>
              <p className="section-title">Get customer feedback with every email</p>
              <p className="section-text">Billsby includes a slot in every email you send out for your own content - a great place to add a feedback link so with every invoice you send out, you can be collecting more information about how your customers feel.</p>
              <p className="section-text">Plus, you can change up the footer content whenever you want, keeping things fresh and relevant for your customers.</p>
            </div>
            <div className="ebook-image">
              <Img filename="feedback.png" alt="Use in-life feedback to identify potential churn risks" />
            </div>
          </div>
        </div>
        <div className="section-ebook">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">4. Increase the utilization of your product</h2>
              <p className="section-text">It’s a simple fact, but it’s true. Customers don’t cancel things they use - so increasing the utilization of your product is a sure-fire way to remain front of mind and deflect churn. For physical product companies like quip, this is insanely easy. Every morning, their customers wake-up and brush their teeth with their quip electric toothbrush - embedding the brand as part of their daily routine. You might not be so lucky. But if your product has a physical location, think about running special events on certain days (like classes at gyms, or discounted events at membership clubs) to attract customers back in.</p>
              <p className="section-text">Software-as-a-Service companies can increase utilization by encouraging the use of their app for daily, weekly and monthly tasks. Task management app Basecamp uses check-in questions as a way to mail out a daily email and encourage users to log back in and engage with their service.</p>
              <p className="section-text">Media companies like Netflix use a recommendation engine to surface new content that keeps their customers coming back, and push out recommendations via email and social media to remind customers to check back in to the service. Even if your subscription is - by its nature - only delivered once a month - like a magazine subscription or a membership box, you can still increase product utilization by increasing the communication around your offering.</p>
              <p className="section-text">In week one, you send the box out - then in week two, email to get feedback on the box (tip three!), in week three, send another message to promote referrals (tip fourteen), in week four, tease the content of the box they’re about to receive, and then in week five, it’s shipping notification time - the box you’ve been hyping them for all month is here!</p>
              <p className="section-title">Every service email is an opportunity to increase utilization</p>
              <p className="section-text">If your customer has opted-out of marketing messages, it might make it harder for you to reach out to them about reasons to come back and use your service. With Billsby, inside the service notifications we send, there’s an opportunity to include an eye-catching graphic advertisement.</p>
              <p className="section-text"> You can use this to promote the events you’ve set up to help increase utilization.</p>
            </div>
            <div className="ebook-image">
              <img src={require('../../../images/utilization-product.svg')}  alt="utilization-product"/>
            </div>
          </div>
        </div>
        <div className="section-ebook w-full">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">5. Charge upfront</h2>
              <p className="section-text">The biggest secret to companies with low churn? They charge upfront with annual plans. According to the 2018 MRR churn study, companies with a high proportion of customers on annual plans see significantly reduced month-on-month revenue churn.</p>
              <p className="section-text">How significant? Well, companies in the study that didn’t use annual plans at all saw median churn rates of 10.4%. This dropped dramatically to 5.63% for companies who had between a quarter and half of their customers on annual contracts, and to 2.59% for companies with more than three-quarters of their base committed for the year.</p>
              <p className="section-text">Think about what point in the customer lifecycle it makes sense to ask for an annual commitment. You could soften the blow by offering a money-back guarantee to new annual customers for the first couple months of membership to overcome any objections around the commitment.</p>
              <p className="section-text">Some companies do this upfront by offering a discount - but you don’t necessarily need to do this to secure an annual deal. Perhaps you could offer additional services or an account manager instead. Business like Atlassian Statuspage encourage enterprise customers to subscribe annually by restricting the ability to</p>
            </div>
          </div>
        </div>
        <div id="ebookForm" className="ebook-form">
          <div className="container">
            <div className="form-wrapper container-flex">
              <form>
                <h2 className="section-heading">Download our free eBook today to learn the rest of our ways to deliver great customer service</h2>

                <div className="form-group">
                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter you name" name="name" value={name} onChange={handleChangeName} />
                  
                  <input type="email" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your email address" name="emailAdd" value={emailAdd} onChange={handleChange}/>

                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your company name" name="companyName" value={company} onChange={handleChangeCompany}/>

                  <button type="submit" className={ `btn-blue ${onSubmit.isSubmitted ? 'btn-submitted' : ''}` } onClick={ handleSubmit } disabled={disabled}>{ onSubmit.message }</button>

                  <p className="section-text">You'll be signed up for marketing communications from Billsby. We respect your <Link to="/privacy">privacy</Link>.</p>
                </div>

              </form>
              <div className="brochure-holder">
                <Img className="main-img" filename="ebook-churn.png" alt="Download our free eBook today to learn the rest of our ways to deliver great customer service" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Churn